import { Box, Typography, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import ReactApexChart from "react-apexcharts";
import Page from "components/Page";
import PieChart from "../components/analytics/PieChart";
import AlertVerificationChart from "../components/analytics/AlertsVerificationChart";

const useStyles = makeStyles(() => ({
  clientDataBox: {
    display: "flex",
    marginTop: "1rem",
    alignItems: "center",
    padding: "16px 24px",
    gap: "13px",
    width: "246px",
    height: "102px",
    background: "#DFFCEE",
    color: "#0A6738",
    borderRadius: "16px",
  },
}));

export default function Analytics() {
  const classes = useStyles();
  return (
    <Page title="Analytics">
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src="/static/img/enercare.jpeg"
          alt="Enercare"
          height={38}
          width={119}
        />
        <h2 style={{ marginLeft: "1rem" }}>Dashboard</h2>
      </Box>
      <Box className={classes.clientDataBox}>
        <img
          src="/static/img/client-data.svg"
          alt="Client Data"
          height={48}
          width={48}
        />
        <Box>
          <h1>145358</h1>
          <Typography>Total Client data</Typography>
        </Box>
      </Box>
      <Grid container spacing={3} mt={2}>
        <Grid item xs={12} sm={6} md={4}>
          <PieChart
            chart_data={[62, 15, 51]}
            headerTitle="Alerts By Month"
            type="month"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <PieChart
            chart_data={[113, 9, 6]}
            headerTitle="Alerts Current Year 2023"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <PieChart
            chart_data={[314, 590, 532]}
            headerTitle="Alerts Previous Year 2022"
          />
        </Grid>
        <Grid item xs={12}>
          <AlertVerificationChart />
        </Grid>
      </Grid>
    </Page>
  );
}
