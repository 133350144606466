import { useState } from "react";
import { merge } from "lodash";
import ReactApexChart from "react-apexcharts";
// material
import {
  Card,
  CardHeader,
  Box,
  Select,
  MenuItem,
  Switch,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
//
import { BaseOptionChart } from "../charts";

// ----------------------------------------------------------------------

// const PinkSwitch = styled(Switch)(({ theme }) => ({
//   "& .MuiSwitch-switchBase.Mui-checked": {
//     color: pink[600],
//     "&:hover": {
//       backgroundColor: alpha(pink[600], theme.palette.action.hoverOpacity),
//     },
//   },
//   "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
//     backgroundColor: pink[600],
//   },
// }));

const CHART_DATA = [
  //   {
  //     name: "Team A",
  //     type: "column",
  //     data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30],
  //   },
  {
    name: "Unverified",
    type: "area",
    data: [1260, 620, 4050, 450, 624, 3205],
  },
  {
    name: "Verified",
    type: "line",
    data: [1350, 2640, 3261, 2154, 351, 4562],
  },
];

const series = [
  {
    name: "Verified",
    data: [1000, 2500, 1500, 1700, 900, 1900],
    color: "#67B46F",
  },
  {
    name: "Unverified",
    data: [1400, 900, 2200, 1300, 2500, 2000],
    color: "#DB1F35",
  },
];

const CustomSelect = styled(Select)({
  borderRadius: 13,
  margin: "24px 24px 0",
  height: "40px",
  width: "fit-content",
});

const monthOptions = [
  "1 months",
  "2 months",
  "3 months",
  "4 months",
  "5 months",
  "6 months",
];

export default function AnalyticsWebsiteVisits() {
  const [month, setMonth] = useState("6 months");

  //   const chartOptions = merge(BaseOptionChart(), {
  //     stroke: { width: [0, 2, 3] },
  //     plotOptions: { bar: { columnWidth: "14%" } },
  //     fill: { type: ["solid", "gradient", "solid"] },
  //     labels: [
  //       "01/09/2022",
  //       "02/10/2022",
  //       "03/11/2022",
  //       "04/12/2022",
  //       "05/01/2023",
  //       "06/02/2023",
  //     ],
  //     xaxis: { type: "datetime" },
  //     tooltip: {
  //       shared: true,
  //       intersect: false,
  //       y: {
  //         formatter: (y: number) => {
  //           if (typeof y !== "undefined") {
  //             return `${y.toFixed(0)}`;
  //           }
  //           return y;
  //         },
  //       },
  //     },
  //   });

  const options = {
    chart: {
      id: "verified-vs-unverified",
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: [
        "Sept '22",
        "Oct '22",
        "Nov '22",
        "Dec '22",
        "Jan '23",
        "Feb '23",
        "Mar '23",
      ],
    },
    yaxis: {
      title: {
        text: "",
      },
      labels: {
        formatter: function (value: any) {
          return value.toLocaleString();
        },
      },
    },
    // stroke: { curve: "smooth", width: [3, 3] },
    // series: series,
  };

  const handleMonthChange = (e: any) => {
    setMonth(e.target.value);
  };

  return (
    <Card>
      <Box style={{ display: "flex" }}>
        <CardHeader title="Alerts Verification" />
        <CustomSelect
          value={month}
          onChange={handleMonthChange}
          variant="outlined"
        >
          {monthOptions.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </CustomSelect>
      </Box>
      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <ReactApexChart
          type="line"
          series={series}
          options={options}
          height={364}
        />
      </Box>
    </Card>
  );
}
