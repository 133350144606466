// hooks
import useAuth from "../hooks/useAuth";
//
import { MAvatar } from "./@material-extend";
import { MAvatarProps } from "./@material-extend/MAvatar";
import createAvatar from "../utils/createAvatar";

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }: MAvatarProps) {
  const { user } = useAuth();

  console.log(user);

  return (
    <MAvatar
      // src={"/static/mock-images/avatars/avatar_default.jpg"}
      alt={"Jaydon Frankie"}
      color={"default"}
      {...other}
    >
      {createAvatar(user?.displayName).name}
    </MAvatar>
  );
}
