import { useDispatch, RootState, useSelector } from "redux/store";
import {
  getAddressList,
  searchAddressList,
  setMapSearchText,
  setMapSearchApplied,
} from "redux/slices/home";
// material
import { Theme } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import { InputBase, IconButton, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    borderRadius: ".5rem",
    background: "#f9fafb",
    border: `1px solid #f0f0f0`,
    width: "50%",
    "&:hover": {
      background: "#f9fafb",
      border: `1px solid ${theme.palette.primary.main}`,
      "& .MuiButtonBase-root": {
        color: theme.palette.primary.main,
      },
    },
    "&:focus-within": {
      boxShadow: `0 0 0 1px ${theme.palette.primary.main}`,
      "& .MuiButtonBase-root": {
        color: theme.palette.primary.main,
      },
    },
  },
  input: {
    marginLeft: theme.spacing(1),
    textOverflow: "ellipsis",
    "&:hover::placeholder, &:focus::placeholder": {
      color: theme.palette.primary.main,
    },
  },
  iconButton: {
    padding: theme.spacing(1),
  },
}));

// ----------------------------------------------------------------------

export default function Searchbar() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { mapSearchText, searchApplied } = useSelector(
    (state: RootState) => state.home
  );
  // const [searchText, setSearchText] = useState("");

  const keyDownHandler = async (e: any) => {
    if (e.key === "Enter") {
      // Execute your function here
      console.log("Enter key pressed!", mapSearchText);
      if (mapSearchText) {
        await dispatch(searchAddressList(mapSearchText));
        dispatch(setMapSearchApplied(true));
      } else {
        await dispatch(getAddressList());
        dispatch(setMapSearchApplied(false));
      }
    } else if (e.keyCode === 8 && !mapSearchText && searchApplied) {
      console.log("back space");
      await dispatch(getAddressList());
      dispatch(setMapSearchApplied(false));
    }
  };

  const onClearSearchHandler = async () => {
    dispatch(setMapSearchText(""));
    await dispatch(getAddressList());
    dispatch(setMapSearchApplied(false));
  };

  return (
    <Paper className={classes.root}>
      <IconButton className={classes.iconButton}>
        <SearchIcon />
      </IconButton>
      <InputBase
        style={{ flex: 1 }}
        placeholder="Search address, owner name, etc"
        classes={{ input: classes.input }}
        value={mapSearchText}
        onChange={(e) => dispatch(setMapSearchText(e.target.value))}
        onKeyDown={keyDownHandler}
      />
      {mapSearchText && (
        <IconButton
          className={classes.iconButton}
          onClick={onClearSearchHandler}
        >
          <CloseIcon />
        </IconButton>
      )}
    </Paper>
  );
}
