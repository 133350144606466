import { useState, useCallback, useEffect } from "react";
import { RootState, useDispatch, useSelector } from "redux/store";
import { useNavigate } from "react-router-dom";
import { getAddressList, searchAddressList } from "redux/slices/home";
import { GoogleMapProvider } from "@ubilabs/google-maps-react-hooks";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import SuperClusterAlgorithm from "../../../../utils/SuperClusterAlgorithm";
import { AddressList } from "../../../../@types/home";
import MapDrawer from "./MapDrawer";
import { PATH_DASHBOARD } from "../../../../routes/paths";
import LoadingScreen from "components/LoadingScreen";
import { useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";

const mapOptions = {
  center: {
    lat: 43.712776,
    lng: -79.005974,
  },
  zoom: 7,
};

const SimpleMap = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [mapContainer, setMapContainer] = useState(null);
  const [map, setMap] = useState(null);
  const [mapKey, setMapKey] = useState(Math.random().toString());
  const [filterdLists, setFilteredLists] = useState<AddressList[]>([]);
  const { addressLists, isLoading, mapSearchText, searchApplied } = useSelector(
    (state: RootState) => state.home
  );
  const onLoad = useCallback((mapObject: any, addressLists: any) => {
    setMap(mapObject);
    addMarkers(mapObject, addressLists);
  }, []);

  useEffect(() => {
    if (mapSearchText) {
      dispatch(searchAddressList(mapSearchText));
    } else {
      dispatch(getAddressList());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setFilteredLists(addressLists);
    setMapKey(Math.random().toString());
  }, [addressLists]);

  function addMarkers(map: any, addressLists: any) {
    const infoWindow = new google.maps.InfoWindow();
    const markers = addressLists?.map((address: any) => {
      const position = address?.location?.split(",");
      const lat = position[0];
      const lng = position[1];
      const marker = new google.maps.Marker({
        position: { lat: +lat, lng: +lng },
      });
      marker.setIcon("/static/img/marker.svg");

      marker.addListener(`${isMobile ? "click" : "mouseover"}`, () => {
        infoWindow.setPosition({ lat: +lat, lng: +lng });
        infoWindow.setContent(`
          <div class='info-window'>
            <img src="/static/img/house2.avif" alt="House" style="width:100%;height:150px;margin-bottom:1em" />
            <div class="info-content">
            <h3>${address.address1}</h3>
            <h4 style="font-weight:normal; margin:0.5em 0">${
              address.address2
            }</h4>
            <div class='house-detail'>
            <span class="iconify info-icon" data-icon="ic:outline-bathtub" data-inline="false"></span>
            <span style="margin-right:8px;">4</span>
            <span class="iconify info-icon" data-icon="ic:round-king-bed" data-inline="false"></span>
            <span style="margin-right:8px;">4</span>
            <span class="iconify info-icon" data-icon="ic:round-garage" data-inline="false"></span>
            <span style="margin-right:8px;">1</span>
            </div>
            <button class="button-outline" id=${lat + lng} >
            <span style="margin-right:0.5em">View</span>
            <span class="iconify info-icon" data-icon="ic:sharp-arrow-right-alt" data-inline="false"></span>
            </button>
            </div>
          </div>
        `);
        infoWindow.open(map, marker);
      });

      google.maps.event.addListener(infoWindow, "domready", () => {
        const someButton = document.getElementById(`${lat + lng}`);
        if (someButton) {
          someButton.addEventListener("click", () => {
            navigate(`${PATH_DASHBOARD.view}?id=${address.id}`);
          });
        }
      });

      return marker;
    });

    new MarkerClusterer({
      markers,
      map,
      algorithm: new SuperClusterAlgorithm({ radius: 200 }),
    });

    setTimeout(() => {
      map.setZoom(6);
    }, 0);
  }

  if (isLoading) return <LoadingScreen />;
  return (
    <GoogleMapProvider
      googleMapsAPIKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY!}
      options={mapOptions}
      mapContainer={mapContainer}
      onLoad={(map) => onLoad(map, filterdLists)}
    >
      <div
        ref={(node: any) => setMapContainer(node)}
        style={{ height: "calc(100vh - 104px)" }}
        key={mapKey}
      />
      {searchApplied && <MapDrawer markers={filterdLists} map={map} />}
    </GoogleMapProvider>
  );
};

export default SimpleMap;
