import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';

type EditorState = {
  isLoading: boolean;
  error: boolean;
  editorContent: string;
};

const initialState: EditorState = {
  isLoading: false,
  error: false,
  editorContent: ''
};

const slice = createSlice({
  name: 'editor',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // SET EDITOR CONTENT
    setContent(state, action) {
      state.editorContent = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { setContent } = slice.actions;
