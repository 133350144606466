import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../store";
// utils
// import axios from "../../utils/axios";
import axios from "axios";
import { ViewProperty } from "../../@types/view";

// ----------------------------------------------------------------------

type ViewState = {
  isLoading: boolean;
  error: boolean;
  viewProperty: ViewProperty | null;
};

const initialState: ViewState = {
  isLoading: false,
  error: false,
  viewProperty: null,
};

const slice = createSlice({
  name: "view",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PROPERTY
    getPropertySuccess(state, action) {
      state.isLoading = false;
      state.viewProperty = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getProperty(id: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_API}/get_property.php?id=${id}`
      );
      console.log(response.data);
      response.data?.length > 0 &&
        dispatch(slice.actions.getPropertySuccess(response.data[0]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
