import { Card, Container, Button, Box } from "@material-ui/core";
import Page from "components/Page";
import SimpleMap from "./GoogleMap";
import MapFilter from "./MapFilter";

export default function LocationFinder() {
  return (
    <Page title="Location | Aloris" position="relative">
      {/* <Container> */}
      {/* <MapFilter /> */}
      {/* <Card> */}
      <SimpleMap />
      {/* </Card> */}
      {/* </Container> */}
    </Page>
  );
}
