import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../store";
// utils
// import axios from "../../utils/axios";
import axios from "axios";
import { SaleList } from "../../@types/sale";

// ----------------------------------------------------------------------

type HomeState = {
  isLoading: boolean;
  error: boolean;
  saleList: SaleList[];
};

const alertRecords = [
  {
    id: 1,
    address: "8 Manitoba Pibrampton-Sandringham-Wellington",
    date: "Apr 23,2021",
  },
  {
    id: 2,
    address: "96 Gorman Ave Vaughan-Vellore Village",
    date: "Apr 15,2021",
  },
  {
    id: 3,
    address: "8 Manitoba Pibrampton-Sandringham-Wellington",
    date: "Apr 23,2021",
  },
  {
    id: 4,
    address: "23 Hoard Ave New Tecumseth-Alliston",
    date: "Apr 11,2021",
  },
  {
    id: 5,
    address: "8 Manitoba Pibrampton-Sandringham-Wellington",
    date: "Apr 18,2021",
  },
  {
    id: 6,
    address: "96 Gorman Ave Vaughan-Vellore Village",
    date: "Apr 15,2021",
  },
  {
    id: 7,
    address: "23 Hoard Ave New Tecumseth-Alliston",
    date: "Apr 11,2021",
  },
];

const initialState: HomeState = {
  isLoading: false,
  error: false,
  saleList: [],
};

const slice = createSlice({
  name: "sale",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PROFILE
    getSaleListSuccess(state, action) {
      state.isLoading = false;
      state.saleList = action.payload;
    },

    // SET Sale List
    setSaleList(state, action) {
      state.saleList = action.payload;
    },

    //Verify Property
    verifyPropertySuccess(state, action) {
      state.isLoading = false;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

// Actions
export const { setSaleList } = slice.actions;

export function getSaleList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_API}/alerts.php?type=new`
      );
      dispatch(slice.actions.getSaleListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function verifyProperty(id: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_API}/verify_match.php?id=${id}`
      );
      dispatch(slice.actions.verifyPropertySuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
