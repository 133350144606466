import jwtDecode from "jwt-decode";
import { verify, sign } from "jsonwebtoken";
import { isBefore, addDays } from "date-fns";
//
// import axios from "./axiosInstance";
import axios from "./axios";
import { TOKEN_NAME } from "config";
import { PATH_AUTH } from "routes/paths";

// ----------------------------------------------------------------------

// const isValidToken = (expiryDate: string) => {
//   if (!expiryDate) {
//     return false;
//   }

//   return isBefore(new Date(), new Date(expiryDate));
// };

const isValidToken = (accessToken: string) => {
  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode<{ exp: number }>(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

//  const handleTokenExpired = (exp) => {
//   let expiredTimer;

//   window.clearTimeout(expiredTimer);
//   const currentTime = Date.now();
//   const timeLeft = exp * 1000 - currentTime;
//   console.log(timeLeft);
//   expiredTimer = window.setTimeout(() => {
//     console.log('expired');
//     // You can do what ever you want here, like show a notification
//   }, timeLeft);
// };

// const setSession = (accessToken: string | null) => {
//   if (accessToken) {
//     const tokenDetails = {
//       accessToken: window.btoa(accessToken),
//       expiryDate: addDays(new Date(), 1),
//     };

//     localStorage.setItem(TOKEN_NAME, window.btoa(JSON.stringify(tokenDetails)));
//   }
// };

const setSession = (accessToken: string | null) => {
  if (accessToken) {
    localStorage.setItem("accessToken", accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    // This function below will handle when token is expired
    // const { exp } = jwtDecode(accessToken);
    // handleTokenExpired(exp);
  } else {
    localStorage.removeItem("accessToken");
    delete axios.defaults.headers.common.Authorization;
  }
};

const removeSession = () => {
  localStorage.removeItem(TOKEN_NAME);
  // window.location.href = PATH_AUTH.login;
  delete axios.defaults.headers.common.Authorization;
};

export { isValidToken, setSession, removeSession, verify, sign };
