import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
// slices
import mailReducer from "./slices/mail";
import chatReducer from "./slices/chat";
import blogReducer from "./slices/blog";
import userReducer from "./slices/user";
import productReducer from "./slices/product";
import calendarReducer from "./slices/calendar";
import kanbanReducer from "./slices/kanban";
import editorReducer from "./slices/editor";
import homeReducer from "./slices/home";
import saleReducer from "./slices/sale";
import soldReducer from "./slices/sold";
import closedReducer from "./slices/closed";
import viewReducer from "./slices/view";

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};

const productPersistConfig = {
  key: "product",
  storage,
  keyPrefix: "redux-",
  whitelist: ["sortBy", "checkout"],
};

const rootReducer = combineReducers({
  mail: mailReducer,
  chat: chatReducer,
  blog: blogReducer,
  user: userReducer,
  calendar: calendarReducer,
  kanban: kanbanReducer,
  editor: editorReducer,
  home: homeReducer,
  sale: saleReducer,
  sold: soldReducer,
  closed: closedReducer,
  view: viewReducer,
  product: persistReducer(productPersistConfig, productReducer),
});

export { rootPersistConfig, rootReducer };
