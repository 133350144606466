import { Suspense, lazy } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";
// layouts
import NewUserCreate from "components/_dashboard/user-management-module/NewUserCreate";
import UsersManagementList from "components/_dashboard/user-management-module/UsersManagement";
import UserProfileManage from "components/_dashboard/user-management-module/UserProfileManage";
import UserAccountManage from "components/_dashboard/user-management-module/UserAccountManage";
// import UserRole from 'components/_dashboard/user-management-module/user-roles/UserRole';
import UserRoleForm from "components/_dashboard/user-management-module/user-roles/UserRoleForm";
import UserRoleList from "components/_dashboard/user-management-module/user-roles/UserRoleList";
import UserRoleCreate from "components/_dashboard/user-management-module/user-roles/UserRoleCreate";
import MainLayout from "../layouts/main";
import DashboardLayout from "../layouts/dashboard";
// guards
import GuestGuard from "../guards/GuestGuard";
import AuthGuard from "../guards/AuthGuard";
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// components
import LoadingScreen from "../components/LoadingScreen";
import LocationFinder from "components/_dashboard/aloris/location-map/LocationFinder";
import SetAlertAloris from "components/_dashboard/aloris/alert/SetAlertAloris";
import SoldProperties from "../pages/Sold";
import ClosedProperties from "../pages/Closed";
import ViewProperty from "../pages/ViewProperty";
import Analytics from "../pages/Analytics";

// ----------------------------------------------------------------------

const Loadable = (Component: React.ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes("/app");

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: "fixed",
            }),
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: "auth",
      children: [
        {
          path: "login",
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: "register",
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: "login-unprotected", element: <Login /> },
        { path: "register-unprotected", element: <Register /> },
        { path: "reset-password", element: <ResetPassword /> },
        { path: "verify", element: <VerifyCode /> },
      ],
    },

    // Dashboard Routes
    {
      path: "app",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: "/", element: <Navigate to="/app/home" replace /> },
        { path: "home", element: <LocationFinder /> },
        { path: "new-listing", element: <NewListing /> },
        { path: "poa", element: <POA /> },
        { path: "new-ownership", element: <NewOwnership /> },
        { path: "view", element: <ViewProperty /> },
        { path: "analytics", element: <Analytics /> },
        {
          path: "user",
          children: [
            {
              path: "/",
              element: <Navigate to="/dashboard/user/profile" replace />,
            },
            { path: "profile", element: <UserProfile /> },
            { path: "cards", element: <UserCards /> },
            { path: "list", element: <UsersManagementList /> },
            { path: "new", element: <NewUserCreate /> },
            { path: "/:name/edit", element: <NewUserCreate /> },
          ],
        },
      ],
    },

    // Main Routes
    {
      path: "*",
      // element: <LogoOnlyLayout />,
      children: [
        { path: "coming-soon", element: <ComingSoon /> },
        { path: "maintenance", element: <Maintenance /> },
        { path: "500", element: <Page500 /> },
        { path: "404", element: <NotFound /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: "/",
      element: <MainLayout />,
      children: [
        { path: "/", element: <Navigate to="/app" replace /> },
        {
          path: "components",
          children: [
            // MATERIAL UI
            { path: "accordion", element: <Accordion /> },
            { path: "alert", element: <Alert /> },
            { path: "autocomplete", element: <Autocomplete /> },
            { path: "avatar", element: <Avatar /> },
            { path: "badge", element: <Badge /> },
            { path: "breadcrumbs", element: <Breadcrumb /> },
            { path: "buttons", element: <Buttons /> },
            { path: "checkbox", element: <Checkbox /> },
            { path: "chip", element: <Chip /> },
            { path: "dialog", element: <Dialog /> },
            { path: "label", element: <Label /> },
            { path: "list", element: <List /> },
            { path: "menu", element: <Menu /> },
            { path: "pagination", element: <Pagination /> },
            { path: "pickers", element: <Pickers /> },
            { path: "popover", element: <Popover /> },
            { path: "progress", element: <Progress /> },
            { path: "radio-button", element: <RadioButtons /> },
            { path: "rating", element: <Rating /> },
            { path: "slider", element: <Slider /> },
            { path: "snackbar", element: <Snackbar /> },
            { path: "stepper", element: <Stepper /> },
            { path: "switch", element: <Switches /> },
            { path: "table", element: <Table /> },
            { path: "tabs", element: <Tabs /> },
            { path: "textfield", element: <Textfield /> },
            { path: "timeline", element: <Timeline /> },
            { path: "tooltip", element: <Tooltip /> },
            { path: "transfer-list", element: <TransferList /> },
            { path: "tree-view", element: <TreeView /> },
            { path: "data-grid", element: <DataGrid /> },
            // EXTRA COMPONENTS
            { path: "chart", element: <Charts /> },
            { path: "map", element: <Map /> },
            { path: "editor", element: <Editor /> },
            { path: "copy-to-clipboard", element: <CopyToClipboard /> },
            { path: "upload", element: <Upload /> },
            { path: "carousel", element: <Carousel /> },
            { path: "multi-language", element: <MultiLanguage /> },
            { path: "animate", element: <Animate /> },
            { path: "mega-menu", element: <MegaMenu /> },
            { path: "form-validation", element: <FormValidation /> },
          ],
        },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import("../pages/authentication/Login")));
const Register = Loadable(
  lazy(() => import("../pages/authentication/Register"))
);
const ResetPassword = Loadable(
  lazy(() => import("../pages/authentication/ResetPassword"))
);
const VerifyCode = Loadable(
  lazy(() => import("../pages/authentication/VerifyCode"))
);

// Aloris Pages
const NewListing = Loadable(lazy(() => import("../pages/NewListing")));
const POA = Loadable(lazy(() => import("../pages/POA")));
const NewOwnership = Loadable(lazy(() => import("../pages/NewOwnership")));

// Dashboard
const UserProfile = Loadable(
  lazy(() => import("../pages/dashboard/UserProfile"))
);
const UserCards = Loadable(lazy(() => import("../pages/dashboard/UserCards")));
const UserList = Loadable(lazy(() => import("../pages/dashboard/UserList")));
const UserAccount = Loadable(
  lazy(() => import("../pages/dashboard/UserAccount"))
);
const UserCreate = Loadable(
  lazy(() => import("../pages/dashboard/UserCreate"))
);
const Mail = Loadable(lazy(() => import("../pages/dashboard/Mail")));
const Calendar = Loadable(lazy(() => import("../pages/dashboard/Calendar")));
const Kanban = Loadable(lazy(() => import("../pages/dashboard/Kanban")));
const FileManager = Loadable(
  lazy(() => import("../pages/dashboard/FileManager"))
);
// Main
const LandingPage = Loadable(lazy(() => import("../pages/LandingPage")));
const ComingSoon = Loadable(lazy(() => import("../pages/ComingSoon")));
const Maintenance = Loadable(lazy(() => import("../pages/Maintenance")));
const Page500 = Loadable(lazy(() => import("../pages/Page500")));
const NotFound = Loadable(lazy(() => import("../pages/Page404")));
// Components
const Accordion = Loadable(
  lazy(() => import("../pages/components-overview/material-ui/Accordion"))
);
const Alert = Loadable(
  lazy(() => import("../pages/components-overview/material-ui/Alert"))
);
const Autocomplete = Loadable(
  lazy(() => import("../pages/components-overview/material-ui/Autocomplete"))
);
const Avatar = Loadable(
  lazy(() => import("../pages/components-overview/material-ui/Avatar"))
);
const Badge = Loadable(
  lazy(() => import("../pages/components-overview/material-ui/Badge"))
);
const Breadcrumb = Loadable(
  lazy(() => import("../pages/components-overview/material-ui/Breadcrumb"))
);
const Buttons = Loadable(
  lazy(() => import("../pages/components-overview/material-ui/buttons"))
);
const Checkbox = Loadable(
  lazy(() => import("../pages/components-overview/material-ui/Checkboxes"))
);
const Chip = Loadable(
  lazy(() => import("../pages/components-overview/material-ui/chips"))
);
const Dialog = Loadable(
  lazy(() => import("../pages/components-overview/material-ui/dialog"))
);
const Label = Loadable(
  lazy(() => import("../pages/components-overview/material-ui/Label"))
);
const List = Loadable(
  lazy(() => import("../pages/components-overview/material-ui/Lists"))
);
const Menu = Loadable(
  lazy(() => import("../pages/components-overview/material-ui/Menus"))
);
const Pagination = Loadable(
  lazy(() => import("../pages/components-overview/material-ui/Pagination"))
);
const Pickers = Loadable(
  lazy(() => import("../pages/components-overview/material-ui/pickers"))
);
const Popover = Loadable(
  lazy(() => import("../pages/components-overview/material-ui/Popover"))
);
const Progress = Loadable(
  lazy(() => import("../pages/components-overview/material-ui/progress"))
);
const RadioButtons = Loadable(
  lazy(() => import("../pages/components-overview/material-ui/RadioButtons"))
);
const Rating = Loadable(
  lazy(() => import("../pages/components-overview/material-ui/Rating"))
);
const Slider = Loadable(
  lazy(() => import("../pages/components-overview/material-ui/Slider"))
);
const Snackbar = Loadable(
  lazy(() => import("../pages/components-overview/material-ui/Snackbar"))
);
const Stepper = Loadable(
  lazy(() => import("../pages/components-overview/material-ui/stepper"))
);
const Switches = Loadable(
  lazy(() => import("../pages/components-overview/material-ui/Switches"))
);
const Table = Loadable(
  lazy(() => import("../pages/components-overview/material-ui/table"))
);
const Tabs = Loadable(
  lazy(() => import("../pages/components-overview/material-ui/Tabs"))
);
const Textfield = Loadable(
  lazy(() => import("../pages/components-overview/material-ui/textfield"))
);
const Timeline = Loadable(
  lazy(() => import("../pages/components-overview/material-ui/Timeline"))
);
const Tooltip = Loadable(
  lazy(() => import("../pages/components-overview/material-ui/Tooltip"))
);
const TransferList = Loadable(
  lazy(() => import("../pages/components-overview/material-ui/transfer-list"))
);
const TreeView = Loadable(
  lazy(() => import("../pages/components-overview/material-ui/TreeView"))
);
const DataGrid = Loadable(
  lazy(() => import("../pages/components-overview/material-ui/data-grid"))
);
//
const Charts = Loadable(
  lazy(() => import("../pages/components-overview/extra/Charts"))
);
const Map = Loadable(
  lazy(() => import("../pages/components-overview/extra/Map"))
);
const Editor = Loadable(
  lazy(() => import("../pages/components-overview/extra/Editor"))
);
const CopyToClipboard = Loadable(
  lazy(() => import("../pages/components-overview/extra/CopyToClipboard"))
);
const Upload = Loadable(
  lazy(() => import("../pages/components-overview/extra/Upload"))
);
const Carousel = Loadable(
  lazy(() => import("../pages/components-overview/extra/Carousel"))
);
const MultiLanguage = Loadable(
  lazy(() => import("../pages/components-overview/extra/MultiLanguage"))
);
const Animate = Loadable(
  lazy(() => import("../pages/components-overview/extra/animate"))
);
const MegaMenu = Loadable(
  lazy(() => import("../pages/components-overview/extra/MegaMenu"))
);
const FormValidation = Loadable(
  lazy(() => import("../pages/components-overview/extra/form-validation"))
);
