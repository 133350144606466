import { useEffect, useState } from "react";
import { merge } from "lodash";
import ReactApexChart from "react-apexcharts";
// material
import { useTheme, styled } from "@material-ui/core/styles";
import {
  Card,
  CardHeader,
  Typography,
  Box,
  MenuItem,
  Select,
} from "@material-ui/core";
// utils
import { fNumber } from "../../utils/formatNumber";
//
import { BaseOptionChart } from "../charts";

// ----------------------------------------------------------------------

const CHART_HEIGHT = 240;
const LEGEND_HEIGHT = 72;

const ChartWrapperStyle = styled("div")(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(4),
  "& .apexcharts-canvas svg": { height: CHART_HEIGHT },
  "& .apexcharts-canvas svg,.apexcharts-canvas foreignObject": {
    overflow: "visible",
  },
  "& .apexcharts-legend": {
    display: "none",
    // height: LEGEND_HEIGHT,
    // alignContent: "center",
    // position: "relative !important" as "relative",
    // borderTop: `solid 1px ${theme.palette.divider}`,
    // top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
  },
}));

const CustomSelect = styled(Select)({
  borderRadius: 13,
  margin: "24px 24px 0",
  height: "40px",
  width: "fit-content",
});

const dateOptions = ["Jan 2023", "Feb 2023"];

// ----------------------------------------------------------------------

type PieChartProps = {
  chart_data: number[];
  headerTitle: string;
  type?: string;
};

export default function PieChart({
  chart_data,
  headerTitle,
  type,
}: PieChartProps) {
  const theme = useTheme();
  const totalListing = chart_data.reduce((a, b) => a + b, 0);
  const [month, setMonth] = useState("Jan 2023");
  const [data, setData] = useState(chart_data);

  const handleMonthChange = (e: any) => {
    setMonth(e.target.value);
  };

  useEffect(() => {
    if (month === "Jan 2023") setData(chart_data);
    else if (month === "Feb 2023") setData([52, 35, 41]);
    else setData(chart_data);
  }, [chart_data, month]);

  const chartOptions = merge(BaseOptionChart(), {
    colors: [
      theme.palette.chart.blue[0],
      theme.palette.primary.main,
      theme.palette.chart.red[0],
    ],
    labels: ["New Listing", "Sold", "New ownership"],
    stroke: { colors: [theme.palette.background.paper] },
    legend: { floating: true, horizontalAlign: "center" },
    dataLabels: { enabled: true, dropShadow: { enabled: false } },
    tooltip: {
      fillSeriesColor: false,
      y: {
        formatter: (seriesName: string) => fNumber(seriesName),
        title: {
          formatter: (seriesName: string) => `${seriesName}`,
        },
      },
    },
    plotOptions: {
      pie: { donut: { labels: { show: false } } },
    },
  });

  return (
    <Card>
      <Box style={{ display: "flex", justifyContent: "space-between" }}>
        <CardHeader title={headerTitle} />
        {type === "month" && (
          <CustomSelect
            value={month}
            onChange={handleMonthChange}
            variant="outlined"
          >
            {dateOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </CustomSelect>
        )}
      </Box>
      <ChartWrapperStyle dir="ltr">
        <ReactApexChart
          type="pie"
          series={data}
          options={chartOptions}
          height={280}
        />
      </ChartWrapperStyle>
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "1rem 0",
        }}
      >
        <Typography style={{ margin: "0.5rem" }}>Total Match:</Typography>
        <Typography component="span" fontWeight="bold">
          {totalListing} Listing
        </Typography>
      </Box>
    </Card>
  );
}
