import { useState, FC } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  List,
  ListItem,
  ListItemText,
  IconButton,
  Divider,
  Typography,
  Link,
} from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import ArrowUp from "@material-ui/icons/KeyboardArrowUp";
import ArrowDown from "@material-ui/icons/KeyboardArrowDown";
import { PATH_DASHBOARD } from "../../../../routes/paths";

import { AddressList } from "../../../../@types/home";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      position: "absolute",
      bottom: "30px",
      left: "30px",
      width: "300px",
      backgroundColor: "#fff",
      boxShadow: "-2px 0px 4px rgba(0, 0, 0, 0.3)",
      zIndex: "1000",
      transition: "all 0.3s ease-in-out",
      borderRadius: ".5rem",
    },
    openDrawer: {
      maxHeight: "400px",
      overflowY: "auto",
      "& .MuiList-root": {
        display: "block",
      },
    },
    closeDrawer: {
      maxHeight: "40px",
      overflowY: "hidden",
      transition: "all 0.3s ease-in-out",
      "& .MuiList-root": {
        display: "none",
      },
    },
  })
);

type MapDrawerProps = {
  markers: AddressList[];
  map: any;
};

const MapDrawer: FC<MapDrawerProps> = ({ markers, map }) => {
  const [drawerOpen, setDrawerOpen] = useState(true);
  const classes = useStyles();

  const handleMarkerClick = (marker: any) => {
    console.log(marker);
    const position = marker?.location?.split(",");
    console.log(position);
    const latLng = new google.maps.LatLng(+position[0], +position[1]);
    console.log(latLng.lat(), latLng.lng());
    map.setCenter(latLng);
    setTimeout(() => {
      map.setZoom(15);
    }, 0);
  };

  return (
    <div>
      <div
        className={`${classes.drawer} ${
          drawerOpen ? classes.openDrawer : classes.closeDrawer
        }`}
      >
        <div
          style={{
            position: "sticky",
            top: 0,
            background: "white",
            zIndex: 1,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0 1rem",
            borderRadius: ".5rem",
          }}
        >
          <h4>Property</h4>
          <IconButton onClick={() => setDrawerOpen(!drawerOpen)}>
            {drawerOpen ? <ArrowDown /> : <ArrowUp />}
          </IconButton>
        </div>
        <List>
          {markers.map((marker, index) => (
            <div key={index}>
              <Divider />
              <ListItem
                key={index}
                onClick={() => handleMarkerClick(marker)}
                style={{ cursor: "pointer" }}
              >
                <ListItemText
                  primaryTypographyProps={{
                    variant: "subtitle1",
                    component: "div",
                  }}
                  secondaryTypographyProps={{ component: "div" }}
                  primary={marker.debtor_name}
                  secondary={
                    <>
                      <Typography variant="body2" component="span">
                        {marker.address1}
                      </Typography>
                      <br />
                      {marker.address2 && (
                        <>
                          <Typography variant="body2" component="span">
                            {marker.address2}
                          </Typography>
                          <br />
                        </>
                      )}
                      <Link
                        component={RouterLink}
                        key={index}
                        to={`${PATH_DASHBOARD.view}?id=${marker.id}`}
                        underline="none"
                        variant="caption"
                        sx={{
                          color: "text.secondary",
                          transition: (theme) =>
                            theme.transitions.create("all"),
                          "&:hover": { color: "primary.main" },
                        }}
                      >
                        View Property
                      </Link>
                    </>
                  }
                />
              </ListItem>
            </div>
          ))}
        </List>
      </div>
    </div>
  );
};

export default MapDrawer;
