import { Link as RouterLink } from "react-router-dom";
// material
import { styled } from "@material-ui/core/styles";
import { Box, Card, Link, Container, Typography } from "@material-ui/core";
// routes
import { PATH_AUTH } from "../../routes/paths";
// components
import Page from "../../components/Page";
import { MHidden } from "../../components/@material-extend";
import { LoginForm } from "../../components/authentication/login";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: "50%",
  maxHeight: "100vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  borderRadius: 0,
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  maxHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(6, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  return (
    <RootStyle title="Login | Minimal-UI">
      <MHidden width="mdUp">
        <Box sx={{ mt: 3, mx: "auto" }} height="75px" maxWidth="50%">
          <img
            src="/static/img/aloris_logo.svg"
            alt="login"
            height="100%"
            width="100%"
          />
        </Box>
      </MHidden>
      <MHidden width="mdDown">
        <SectionStyle>
          <img
            src="/static/img/login-img.png"
            alt="login"
            style={{ maxHeight: "100%", objectFit: "cover" }}
          />
        </SectionStyle>
      </MHidden>
      <Container maxWidth="sm" style={{ margin: "auto" }}>
        <ContentStyle>
          <MHidden width="mdDown">
            <Box sx={{ mb: 8, mx: "auto" }} maxWidth="50%">
              <img
                src="/static/img/aloris_logo.svg"
                alt="login"
                height="100%"
                width="100%"
              />
            </Box>
          </MHidden>
          <Typography variant="h4" sx={{ mb: 4, color: "text.secondary" }}>
            Sign in
          </Typography>

          <LoginForm />
          {/* <Typography
            sx={{ mt: 3, textAlign: "center", color: "text.secondary" }}
          >
            Don’t have an account? &nbsp;
            <Link
              underline="none"
              variant="subtitle2"
              component={RouterLink}
              to={PATH_AUTH.register}
            >
              Create an account
            </Link>
          </Typography> */}
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
