import { map, filter } from "lodash";
import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../store";
// utils
// import axios from "../../utils/axios";
import axios from "axios";
import { AddressList } from "../../@types/home";

// ----------------------------------------------------------------------

type HomeState = {
  isLoading: boolean;
  error: boolean;
  addressLists: AddressList[];
  mapSearchText: string;
  searchApplied: boolean;
};

const initialState: HomeState = {
  isLoading: false,
  error: false,
  addressLists: [],
  mapSearchText: "",
  searchApplied: false,
};

const slice = createSlice({
  name: "home",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    //Set map search text
    setMapSearchText(state, action) {
      state.mapSearchText = action.payload;
    },

    //Set map search applied
    setMapSearchApplied(state, action) {
      state.searchApplied = action.payload;
    },

    // GET PROFILE
    getAddressListSuccess(state, action) {
      state.isLoading = false;
      state.addressLists = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export const { setMapSearchText, setMapSearchApplied } = slice.actions;

export function getAddressList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_API}/addresslist.php`
      );
      dispatch(slice.actions.getAddressListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function searchAddressList(searchText: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_API}/addresslist.php?search=${searchText}`
      );
      dispatch(slice.actions.getAddressListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
