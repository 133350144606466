import { useEffect, useState } from "react";
import { Box, Button, IconButton, Grid } from "@material-ui/core";
import { useSnackbar } from "notistack5";
import ArrowBack from "@material-ui/icons/ArrowBack";
import FilterIcon from "@material-ui/icons/FilterAlt";
import ArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { useTheme, Theme } from "@material-ui/core/styles";
import { DataGrid, GridCellParams } from "@mui/x-data-grid";
import { makeStyles } from "@material-ui/styles";
import { useNavigate, useLocation } from "react-router-dom";
import { RootState, useDispatch, useSelector } from "redux/store";
import { getProperty } from "redux/slices/view";
import { verifyProperty } from "redux/slices/sale";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Page from "components/Page";
import { sentenceCase } from "change-case";
import Label from "components/Label";
import { ViewProperty, OwnerDetails } from "../@types/view";
import axios from "axios";
import { format } from "date-fns";

const notarisedDetails = [
  {
    id: 1,
    date: "Apr 23,2021",
    type: "60 days notice",
    numberId: 1700589,
    transfer: "50%",
    multiple: "No",
    price: "$23000",
    counterPart: "$2300",
    taxation: "$2300",
    deed: "Download",
  },
  {
    id: 2,
    date: "Apr 23,2021",
    type: "Mortgage",
    numberId: 1700589,
    transfer: "50%",
    multiple: "Yes",
    price: "$600000",
    counterPart: "-$670",
    taxation: "-$670",
    deed: "Download",
  },
  {
    id: 3,
    date: "Apr 18,2021",
    type: "Sale",
    numberId: 17005456,
    transfer: "-",
    multiple: "No",
    price: "$750000",
    counterPart: "$234",
    taxation: "$234",
    deed: "Not Available",
  },
  {
    id: 4,
    date: "Apr 15,2021",
    type: "Mortgage",
    numberId: 174589,
    transfer: "50%",
    multiple: "No",
    price: "$500000",
    counterPart: "$5000",
    taxation: "$5000",
    deed: "Not Available",
  },
  {
    id: 5,
    date: "Apr 15,2021",
    type: "Sale",
    numberId: 8945,
    transfer: "-",
    multiple: "Yes",
    price: "$500000",
    counterPart: "$2300",
    taxation: "$2300",
    deed: "Download",
  },
  {
    id: 6,
    date: "Apr 11,2021",
    type: "Mortgage",
    numberId: 147965,
    transfer: "100%",
    multiple: "Yes",
    price: "$500000",
    counterPart: "$560",
    taxation: "$560",
    deed: "Not Available",
  },
];

const useStyles = makeStyles((theme: Theme) => {
  const lightMode = theme.palette.mode === "light";
  return {
    datagrid: {
      "& .MuiDataGrid-columnHeadersInner": {
        color: lightMode ? "rgb(99, 115, 129)" : "rgb(145, 158, 171)",
        backgroundColor: lightMode
          ? "rgb(244, 246, 248)"
          : "rgba(145, 158, 171, 0.16)",
      },
    },
    alertBtn: {
      backgroundColor: theme.palette.primary.light,
      boxShadow: "none",
      color: theme.palette.primary.main,
      "&.MuiButton-root:hover": {
        backgroundColor: theme.palette.primary.main,
        color: "white",
      },
    },
    boxContainer: {
      color: theme.palette.primary.main,
      width: "100%",
      gap: "30px",
      display: "flex",
      padding: "1rem",
      flexWrap: "wrap",
    },
    propertBox: {
      width: "14rem",
      background: theme.palette.primary.light,
      padding: "1.5rem",
      textAlign: "center",
      borderRadius: ".6875rem",
    },
    propertyBoxGrid: {
      color: theme.palette.primary.main,
      background: theme.palette.primary.light,
      padding: "1.5rem",
      textAlign: "center",
      borderRadius: ".6875rem",
    },
    imgContainer: {
      height: "29.375rem",
      "& img": {
        borderRadius: "1.5625rem",
        height: "100%",
        width: "100%",
        objectFit: "cover",
      },
    },
  };
});

function ViewPropertyScreen() {
  const theme = useTheme();
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const queryParams = new URLSearchParams(location.search);
  const { viewProperty, isLoading } = useSelector(
    (state: RootState) => state.view
  );
  const [ownerDetails, setOwnerDetails] = useState<OwnerDetails[]>([]);
  const propertyId = queryParams.get("id");
  const propertyType = queryParams.get("property");
  const [open, setOpen] = useState(false);
  const [position, setPosition] = useState({
    lat: 42.9431754,
    lng: -79.0551233,
  });

  useEffect(() => {
    if (propertyId) dispatch(getProperty(propertyId));
  }, [dispatch, propertyId]);

  useEffect(() => {
    if (viewProperty) {
      const location = viewProperty?.location?.split(",");
      location && setPosition({ lat: +location[0], lng: +location[1] });
      const ownerEmail = viewProperty?.debtor_name
        ?.split(" ")
        ?.join(".")
        ?.toLowerCase();
      const date = new Date(viewProperty?.date);
      const formattedDate = format(date, "MMM d, yyyy");
      const ownerDetails = [
        {
          id: 1,
          name: viewProperty?.debtor_name,
          address: viewProperty.full_address,
          municipality: viewProperty.city,
          date: formattedDate ?? "Feb 15,2023",
          email: `${ownerEmail}@gmail.com`,
          number: viewProperty.home_tel,
          status: "active",
        },
        {
          id: 2,
          name: "Bonnie green",
          address: viewProperty.full_address,
          municipality: viewProperty.city,
          date: "Feb 10,2021",
          email: `bonnie.green@gmail.com`,
          number: "8549752165",
          status: "inactive",
        },
        {
          id: 3,
          name: "Bonnie green",
          address: viewProperty.full_address,
          municipality: viewProperty.city,
          date: "Feb 01,2021",
          email: "bonnie.green@gmail.com",
          number: "8549752165",
          status: "active",
        },
      ];
      setOwnerDetails(ownerDetails);
    }
  }, [viewProperty]);

  const ownerColumns = [
    { field: "name", headerName: "NAME", flex: 1 },
    { field: "address", headerName: "ADDRESS", flex: 1 },
    { field: "municipality", headerName: "MUNICIPALITY", flex: 1 },
    { field: "date", headerName: "DATE", flex: 1 },
    { field: "email", headerName: "EMAIL", flex: 1 },
    { field: "number", headerName: "PHONE NUMBER", flex: 1 },
    {
      field: "status",
      headerName: "STATUS",
      flex: 1,
      renderCell: (params: GridCellParams) => {
        return (
          <Label
            variant={theme.palette.mode === "light" ? "ghost" : "filled"}
            color={(params.row.status === "inactive" && "error") || "success"}
          >
            {sentenceCase(params.row.status)}
          </Label>
        );
      },
    },
  ];

  const notarisedColumn = [
    { field: "date", headerName: "DATE & TIME", flex: 1 },
    { field: "type", headerName: "TYPE", flex: 1 },
    { field: "numberId", headerName: "NUMBER ID", flex: 1 },
    { field: "transfer", headerName: "TRANSFER", flex: 1 },
    { field: "multiple", headerName: "MULTIPLE", flex: 1 },
    { field: "price", headerName: "PRICE SOLD", flex: 1 },
    { field: "counterPart", headerName: "COUNTERPART", flex: 1 },
    { field: "taxation", headerName: "TAXATION BASE", flex: 1 },
    {
      field: "deed",
      headerName: "DEED",
      flex: 1,
      renderCell: (params: GridCellParams) => {
        return (
          <Label
            variant={theme.palette.mode === "light" ? "ghost" : "filled"}
            color={
              (params.row.deed === "Not Available" && "error") || "success"
            }
          >
            {sentenceCase(params.row.deed)}
          </Label>
        );
      },
    },
  ];

  const handleClose = () => {
    setOpen(false);
  };

  const verifyHandler = async () => {
    if (propertyId) {
      try {
        await dispatch(verifyProperty(propertyId));
        enqueueSnackbar("Verify Success", { variant: "success" });
        navigate(-1);
      } catch (e) {
        console.log("[Error]", e);
        enqueueSnackbar("Verify Failed", { variant: "error" });
      }
      setOpen(false);
    }
  };

  return (
    <Page title="Property | Aloris">
      <Box style={{ display: "flex", justifyContent: "space-between" }}>
        <IconButton
          style={{ fontSize: "1rem", color: theme.palette.primary.main }}
          onClick={() => navigate(-1)}
        >
          <ArrowBack style={{ marginRight: "0.2em" }} />
          Back
        </IconButton>
        <h3 style={{ color: theme.palette.primary.main }}>
          {viewProperty?.full_address}
        </h3>
        <Box style={{ visibility: "hidden" }}>
          <Button variant="outlined" style={{ marginRight: "0.8em" }}>
            <ArrowDownIcon style={{ marginRight: "0.2em" }} />
            Actions
          </Button>
          <Button variant="outlined">
            <FilterIcon />
            Filter By
          </Button>
        </Box>
      </Box>
      <Grid container spacing={3} mt={1} justifyContent="center">
        <Grid item xs={10} md={6} className={classes.imgContainer}>
          <img
            src={`https://maps.googleapis.com/maps/api/streetview?size=400x400&location=${viewProperty?.full_address?.replace(
              /[^a-zA-Z0-9]/g,
              " "
            )}&key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`}
            alt="Home"
          />
        </Grid>
        <Grid item xs={10} md={6} className={classes.imgContainer}>
          <img
            src={`https://maps.googleapis.com/maps/api/staticmap?&key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}&size=370x270&maptype=roadmap&markers=size:small%7Ccolor:0xFE9200%7C${position.lat},${position.lng}`}
            alt="House location"
          />
        </Grid>
      </Grid>
      <h3 style={{ marginTop: "1rem" }}>About Property</h3>
      <Box sx={{ flexGrow: 1, marginTop: "1rem" }}>
        <Grid container spacing={1}>
          <Grid container item spacing={3}>
            <Grid item xs={10} md={3}>
              <Box className={classes.propertyBoxGrid}>
                <h4 style={{ marginBottom: "0.5rem" }}>Bedroom</h4>
                <h4>{viewProperty?.Bedroom ? viewProperty?.Bedroom : "NA"}</h4>
              </Box>
            </Grid>
            <Grid item xs={10} md={3}>
              <Box className={classes.propertyBoxGrid}>
                <h4 style={{ marginBottom: "0.5rem" }}>Bathroom</h4>
                <h4>
                  {viewProperty?.Bathroom ? viewProperty?.Bathroom : "NA"}
                </h4>
              </Box>
            </Grid>
            <Grid item xs={10} md={3}>
              <Box className={classes.propertyBoxGrid}>
                <h4 style={{ marginBottom: "0.5rem" }}>Car Parking</h4>
                <h4>{viewProperty?.Parking ? viewProperty?.Parking : "NA"}</h4>
              </Box>
            </Grid>
            {/* <Grid item xs={10} md={3}>
              <Box className={classes.propertyBoxGrid}>
                <h4 style={{ marginBottom: "0.5rem" }}>Super Area</h4>
                <h4>{"NA"}</h4>
              </Box>
            </Grid>
            <Grid item xs={10} md={3}>
              <Box className={classes.propertyBoxGrid}>
                <h4 style={{ marginBottom: "0.5rem" }}>Carpet Area</h4>
                <h4>{"NA"}</h4>
              </Box>
            </Grid>
            <Grid item xs={10} md={3}>
              <Box className={classes.propertyBoxGrid}>
                <h4 style={{ marginBottom: "0.5rem" }}>Floors</h4>
                <h4>{"NA"}</h4>
              </Box>
            </Grid>
            <Grid item xs={10} md={3}>
              <Box className={classes.propertyBoxGrid}>
                <h4 style={{ marginBottom: "0.5rem" }}>Water Availability</h4>
                <h4>{"NA"}</h4>
              </Box>
            </Grid> */}
            <Grid item xs={10} md={3}>
              <Box className={classes.propertyBoxGrid}>
                <h4 style={{ marginBottom: "0.5rem" }}>Account No</h4>
                <h4>
                  {viewProperty?.DEBTOR_NO ? viewProperty?.DEBTOR_NO : "NA"}
                </h4>
              </Box>
            </Grid>
            {/* <Grid item xs={10} md={3}>
              <Box className={classes.propertyBoxGrid}>
                <h4 style={{ marginBottom: "0.5rem" }}>Debtor Name</h4>
                <h4>
                  {viewProperty?.debtor_name
                    ? viewProperty?.debtor_name
                    : "NA"}
                </h4>
              </Box>
            </Grid>
            <Grid item xs={10} md={3}>
              <Box className={classes.propertyBoxGrid}>
                <h4 style={{ marginBottom: "0.5rem" }}>Phone Number</h4>
                <h4>
                  {viewProperty?.home_tel ? viewProperty?.home_tel : "NA"}
                </h4>
              </Box>
            </Grid> */}
          </Grid>
        </Grid>
      </Box>
      <h3 style={{ margin: "2rem 0 1rem" }}>
        Owner address at the time of transaction
      </h3>
      <DataGrid
        rows={ownerDetails}
        columns={ownerColumns}
        hideFooter
        autoHeight
        className={classes.datagrid}
        loading={isLoading}
      />
      <h3 style={{ margin: "2rem 0 1rem" }}>
        Notarised Transaction collected by Aloris
      </h3>
      <DataGrid
        rows={notarisedDetails}
        columns={notarisedColumn}
        hideFooter
        autoHeight
        className={classes.datagrid}
      />
      {!!propertyId && !!propertyType && (
        <Button
          variant="contained"
          className={classes.alertBtn}
          onClick={() => setOpen(true)}
          style={{ marginTop: "2rem" }}
        >
          Verify
        </Button>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent>
          <DialogContentText>
            Clicking verified will remove alert from counter!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            No
          </Button>
          <Button onClick={verifyHandler} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Page>
  );
}

export default ViewPropertyScreen;
