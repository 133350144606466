export const htmlEditors = [
  {
    id: 1,
    img: '/static/icons/file/file_type_word.svg',
    title: 'Lueilwitz and Sons',
    subTitle: 'Assumenda nam repudiandae rerum fugiat vel maxime.',
    link: 'https://reactjs.org'
  },
  {
    id: 2,
    img: '/static/icons/file/file_type_word.svg',
    title: 'Gleichner, Mueller and Tromp',
    subTitle: 'Architecto vel voluptatibus alias a aut non maxime ipsa voluptates.',
    link: 'https://reactjs.org'
  },
  {
    id: 3,
    img: '/static/icons/file/file_type_word.svg',
    title: 'Hegmann, Kreiger and Bayer',
    subTitle:
      'Distinctio architecto debitis eligendi consequatur unde id modi accusantium possimus.',
    link: 'https://reactjs.org'
  },
  {
    id: 4,
    img: '/static/icons/file/file_type_word.svg',
    title: 'Grimes Inc',
    subTitle: 'Laboriosam blanditiis quo sed et qui esse ipsam necessitatibus sed.',
    link: 'https://reactjs.org'
  },
  {
    id: 5,
    img: '/static/icons/file/file_type_word.svg',
    title: 'Heidenreich, Stokes and Parker',
    subTitle:
      'Occaecati est et illo quibusdam accusamus qui. Incidunt aut et molestiae ut facere aut. Est quidem iusto praesentium excepturi harum nihil tenetur facilis. Ut omnis voluptates nihil accusantium doloribus eaque debitis.',
    link: 'https://reactjs.org'
  },
  {
    id: 6,
    img: '/static/icons/file/file_type_word.svg',
    title: 'Heidenreich, Stokes and Parker',
    subTitle:
      'Occaecati est et illo quibusdam accusamus qui. Incidunt aut et molestiae ut facere aut. Est quidem iusto praesentium excepturi harum nihil tenetur facilis. Ut omnis voluptates nihil accusantium doloribus eaque debitis.',
    link: 'https://reactjs.org'
  },
  {
    id: 7,
    img: '/static/icons/file/file_type_word.svg',
    title: 'Heidenreich, Stokes and Parker',
    subTitle:
      'Occaecati est et illo quibusdam accusamus qui. Incidunt aut et molestiae ut facere aut. Est quidem iusto praesentium excepturi harum nihil tenetur facilis. Ut omnis voluptates nihil accusantium doloribus eaque debitis.',
    link: 'https://reactjs.org'
  },
  {
    id: 8,
    img: '/static/icons/file/file_type_word.svg',
    title: 'Heidenreich, Stokes and Parker',
    subTitle:
      'Occaecati est et illo quibusdam accusamus qui. Incidunt aut et molestiae ut facere aut. Est quidem iusto praesentium excepturi harum nihil tenetur facilis. Ut omnis voluptates nihil accusantium doloribus eaque debitis.',
    link: 'https://reactjs.org'
  },
  {
    id: 9,
    img: '/static/icons/file/file_type_word.svg',
    title: 'Heidenreich, Stokes and Parker',
    subTitle:
      'Occaecati est et illo quibusdam accusamus qui. Incidunt aut et molestiae ut facere aut. Est quidem iusto praesentium excepturi harum nihil tenetur facilis. Ut omnis voluptates nihil accusantium doloribus eaque debitis.',
    link: 'https://reactjs.org'
  },
  {
    id: 10,
    img: '/static/icons/file/file_type_word.svg',
    title: 'Heidenreich, Stokes and Parker',
    subTitle:
      'Occaecati est et illo quibusdam accusamus qui. Incidunt aut et molestiae ut facere aut. Est quidem iusto praesentium excepturi harum nihil tenetur facilis. Ut omnis voluptates nihil accusantium doloribus eaque debitis.',
    link: 'https://reactjs.org'
  },
  {
    id: 11,
    img: '/static/icons/file/file_type_word.svg',
    title: 'Heidenreich, Stokes and Parker',
    subTitle:
      'Occaecati est et illo quibusdam accusamus qui. Incidunt aut et molestiae ut facere aut. Est quidem iusto praesentium excepturi harum nihil tenetur facilis. Ut omnis voluptates nihil accusantium doloribus eaque debitis.',
    link: 'https://reactjs.org'
  }
];
