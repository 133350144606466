import { useEffect } from "react";
// routes
import { PATH_DASHBOARD } from "../../routes/paths";
import { RootState, useDispatch, useSelector } from "redux/store";
import { getSaleList } from "redux/slices/sale";
import { getSoldList } from "redux/slices/sold";
import { getClosedList } from "redux/slices/closed";
// components
import { SvgIcon } from "@material-ui/core";
import { Icon } from "@iconify/react";
import locationIcon from "@iconify/icons-ic/location-on";
import { Badge } from "@material-ui/core";
import { ReactComponent as UpForSaleIcon } from "../../assets/img/upforsale.svg";
import { ReactComponent as AnalyticIcon } from "../../assets/img/analytics.svg";
import { ReactComponent as SoldIcon } from "../../assets/img/Sold.svg";
import { ReactComponent as ClosedIcon } from "../../assets/img/closed.svg";
// location-on

// ----------------------------------------------------------------------

const getSvgIcon = (name: string) => (
  // <SvgIconStyle src={analyticIcon} sx={{ width: "100%", height: "100%" }} />
  <SvgIcon color="inherit">
    <AnalyticIcon color="primary" />
  </SvgIcon>
);

const getIcon = (name: any) => (
  <SvgIcon color="inherit">
    <Icon icon={name} width={24} height={24} />
  </SvgIcon>
);

const NotificationIcon = ({ NavIcon, count }: any) => {
  // const dispatch = useDispatch();
  // useEffect(() => {
  //   async function fetchData() {
  //     await Promise.all([
  //       dispatch(getSaleList()),
  //       dispatch(getSoldList),
  //       dispatch(getClosedList),
  //     ]);
  //   }
  //   fetchData();
  // }, [dispatch]);

  return (
    <Badge badgeContent={count} color="primary" showZero={true} max={9999}>
      <SvgIcon color="inherit">
        <NavIcon style={{ color: "green" }} />
      </SvgIcon>
    </Badge>
  );
};

const SaleNotificationIcon = () => {
  const { saleList } = useSelector((state: RootState) => state.sale);
  return <NotificationIcon NavIcon={UpForSaleIcon} count={saleList.length} />;
};

const SoldNotificationIcon = () => {
  const { soldList } = useSelector((state: RootState) => state.sold);
  return <NotificationIcon NavIcon={SoldIcon} count={soldList.length} />;
};
const ClosedNotificationIcon = () => {
  const { closedList } = useSelector((state: RootState) => state.closed);
  return <NotificationIcon NavIcon={ClosedIcon} count={closedList.length} />;
};

const ICONS = {
  home: getIcon(locationIcon),
  sale: <SaleNotificationIcon />,
  analytics: getSvgIcon("analytics"),
  sold: <SoldNotificationIcon />,
  close: <ClosedNotificationIcon />,
};

const sidebarConfig = [
  {
    subheader: "aloris",
    items: [
      {
        title: "Home",
        path: PATH_DASHBOARD.home,
        icon: ICONS.home,
      },
      {
        title: "New Listing",
        path: PATH_DASHBOARD.newListing,
        icon: ICONS.sale,
      },
      {
        title: "POA",
        path: PATH_DASHBOARD.poa,
        icon: ICONS.sold,
      },
      {
        title: "New Ownership",
        path: PATH_DASHBOARD.newOwnership,
        icon: ICONS.close,
      },
      {
        title: "Analytics",
        path: PATH_DASHBOARD.analytics,
        icon: ICONS.analytics,
      },
    ],
  },
];

export default sidebarConfig;
