import { useEffect } from "react";
import { useDispatch } from "redux/store";
// routes
import Router from "./routes";
// theme
import ThemeConfig from "./theme";
// hooks
import useAuth from "./hooks/useAuth";
// components
import ScrollToTop from "./components/ScrollToTop";
import LoadingScreen from "./components/LoadingScreen";
import GoogleAnalytics from "./components/GoogleAnalytics";
import NotistackProvider from "./components/NotistackProvider";
import { getSaleList } from "redux/slices/sale";
import { getSoldList } from "redux/slices/sold";
import { getClosedList } from "redux/slices/closed";

// ----------------------------------------------------------------------

export default function App() {
  const { isInitialized, isAuthenticated } = useAuth();
  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchData() {
      await Promise.all([
        dispatch(getSaleList()),
        dispatch(getSoldList()),
        dispatch(getClosedList()),
      ]);
    }
    isAuthenticated && fetchData();
  }, [dispatch, isAuthenticated]);

  return (
    <ThemeConfig>
      <NotistackProvider>
        <ScrollToTop />
        <GoogleAnalytics />
        {isInitialized ? <Router /> : <LoadingScreen />}
      </NotistackProvider>
    </ThemeConfig>
  );
}
