import axios from "axios";
import { localAPIBaseUrl, TOKEN_NAME } from "config";
import { PATH_AUTH } from "routes/paths";

export const axiosPlain = axios.create({
  baseURL: localAPIBaseUrl,
});

const axiosInstance = axios.create({
  baseURL: localAPIBaseUrl,
});

axiosInstance.interceptors.request.use(
  (config: any) => {
    if (!config.headers["Authorization"]) {
      const tokenDecode = window.localStorage.getItem(TOKEN_NAME);
      const { accessToken = null } = tokenDecode
        ? JSON.parse(window.atob(tokenDecode) || "")
        : {};
      if (accessToken) {
        const userToken = window.atob(accessToken);
        config.headers["Authorization"] = `Bearer ${userToken}`;
      }
    }
    return config;
  },
  (error) => {
    console.log("[Error]", error);
    Promise.reject(error);
  }
);

export const interceptor = (logout: Function) => {
  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      console.log("[Error]", error);
      if (!!error.isAxiosError && !error.response) {
        throw new Error(`Unable to connect to network`);
      }
      if (error.response?.status === 401 || error.response?.status === 403) {
        console.log("Response inter********", error.response);
        logout();
        localStorage.removeItem(TOKEN_NAME);
        window.location.href = PATH_AUTH.login;
        delete axiosInstance.defaults.headers.common.Authorization;
      }

      return Promise.reject(error);
    }
  );
};

export default axiosInstance;
