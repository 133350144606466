import {
  createContext,
  ReactNode,
  useEffect,
  useReducer,
  useCallback,
} from "react";
// utils
import { axiosPlain, interceptor } from "../utils/axiosInstance";
import axios from "../utils/axios";
import { isValidToken, setSession, removeSession } from "../utils/jwt";
// @types
import {
  ActionMap,
  AuthState,
  AuthUser,
  JWTContextType,
} from "../@types/authentication";
import { TOKEN_NAME } from "config";

// ----------------------------------------------------------------------

enum Types {
  Initial = "INITIALIZE",
  Login = "LOGIN",
  Logout = "LOGOUT",
  Register = "REGISTER",
}

type JWTAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
  // [Types.Login]: {};
  [Types.Login]: {
    user: AuthUser;
  };
  [Types.Logout]: undefined;
  [Types.Register]: {
    user: AuthUser;
  };
};

export type JWTActions =
  ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>];

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const JWTReducer = (state: AuthState, action: JWTActions) => {
  switch (action.type) {
    case "INITIALIZE":
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
      };
    // case "LOGIN":
    //   return {
    //     ...state,
    //     // isAuthenticated: true,
    //     // user: action.payload.user,
    //   };
    case "LOGIN":
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case "LOGOUT":
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };

    case "REGISTER":
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };

    default:
      return state;
  }
};

const AuthContext = createContext<JWTContextType | null>(null);

function AuthProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem("accessToken");

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          const response = await axios.get("/api/account/my-account");
          const { user } = response.data;

          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (email: string, password: string) => {
    const response = await axios.post("/api/account/login", {
      email,
      password,
    });
    const { accessToken, user } = response.data;
    console.log("accessToken", accessToken);
    setSession(accessToken);
    dispatch({
      type: Types.Login,
      payload: {
        user,
      },
    });
  };

  // const initialize = useCallback(async (remember = true) => {
  //   try {
  //     const tokenDecode = window.localStorage.getItem(TOKEN_NAME);

  //     const { accessToken = null, expiryDate = null } = tokenDecode
  //       ? JSON.parse(window.atob(tokenDecode) || "")
  //       : {};

  //     if (accessToken && isValidToken(expiryDate)) {
  //       const response = await axios.get(`/get-profile`);
  //       const { data } = response.data;
  //       interceptor(logout);
  //       dispatch({
  //         type: Types.Initial,
  //         payload: {
  //           isAuthenticated: true,
  //           user: data,
  //         },
  //       });
  //     } else {
  //       dispatch({
  //         type: Types.Initial,
  //         payload: {
  //           isAuthenticated: false,
  //           user: null,
  //         },
  //       });
  //     }
  //   } catch (err) {
  //     dispatch({
  //       type: Types.Initial,
  //       payload: {
  //         isAuthenticated: false,
  //         user: null,
  //       },
  //     });
  //   }

  //   if (!remember) {
  //     localStorage.removeItem(TOKEN_NAME);
  //   }
  // }, []);

  // useEffect(() => {
  //   initialize();
  // }, [initialize]);

  // const login = async (email: string, password: string, remember: boolean) => {
  //   try {
  //     const response = await axiosPlain.post("/login", {
  //       email,
  //       password,
  //     });
  //     const { data } = response.data;
  //     if (data.token) {
  //       setSession(data.token);
  //       dispatch({
  //         type: Types.Login,
  //         payload: {},
  //       });
  //       initialize(remember);
  //     }
  //   } catch (error: any) {
  //     throw Error(error?.response?.data?.message);
  //   }
  // };

  const register = async (email: string, password: string, name: string) => {
    try {
      const response = await axiosPlain.post("/register", {
        email,
        password,
        name,
      });
      const {
        data: { token, ...userData },
      } = response.data;
      if (token) {
        setSession(token);
        dispatch({
          type: Types.Register,
          payload: {
            user: userData,
          },
        });
      }
    } catch (error: any) {
      throw Error(error?.response?.data?.message);
    }
  };

  // const logout = async () => {
  //   removeSession();
  //   dispatch({ type: Types.Logout });
  // };

  const logout = async () => {
    setSession(null);
    dispatch({ type: Types.Logout });
  };

  const resetPassword = (email: string) => console.log(email);

  const updateProfile = () => {};

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        login,
        logout,
        register,
        resetPassword,
        updateProfile,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
