import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../store";
// utils
// import axios from "../../utils/axios";
import axios from "axios";
import { SoldList } from "../../@types/sold";

// ----------------------------------------------------------------------

type HomeState = {
  isLoading: boolean;
  error: boolean;
  soldList: SoldList[];
};

const initialState: HomeState = {
  isLoading: false,
  error: false,
  soldList: [],
};

const slice = createSlice({
  name: "sold",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PROFILE
    getSoldListSuccess(state, action) {
      state.isLoading = false;
      state.soldList = action.payload;
    },

    // SET Sale List
    setSoldList(state, action) {
      state.soldList = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

// Actions
export const { setSoldList } = slice.actions;

export function getSoldList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_API}/alerts.php?type=sold`
      );
      dispatch(slice.actions.getSoldListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
